<template>
  <div class="estoque-body">
    <GenericTable
      ref="listaInventario"
      name="material"
      title="Inventário de materiais"
      rastreabilidadeRoute="materialRastrear"
      :disabledTitle="true"
      :cols="[
        'ID',
        'Descrição',
        'Qtd de peças',
        'Tipo',
        'Unidade',
        'Médico',
        'Setor',
        'Fornecedor',
      ]"
      :cols-name="[
        'id',
        'descricao',
        'quantidade',
        'tipo_material',
        'Unidade',
        'medico',
        'Setor',
        'fornecedor',
      ]"
      :cols-map="
        (i) => [
          i.shownId,
          i.descricao,
          i.quantidade,
          i.tipo_material,
          i.unidade,
          i.medico,
          i.setor,
          i.fornecedor,
        ]
      "
      :route="materialRoute"
      :permissionsToWrite="['rw_material']"
      :filters="filtersLista"
      idKey="id_material"
      hasPagination
      :noadd="true"
    >
    <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>QR Code</label>
              <b-form-input
                v-model="filtersLista.code"
                autocomplete="off"
                class="invision-input sm"
                ref="filter_code"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

         <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>ID</label>
              <b-form-input
                v-model="filtersLista.ids"
                autocomplete="off"
                class="invision-input sm"
                ref="filter_id"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Unidade</label>
              <b-form-select
                name="unidade"
                labelKey="nome"
                v-model="filtersLista.id_unidade"
                :options="optionsUnidade"
                :disabled="!hasBlocoOrCmeOrOpme"
                ref="filters_id_unidade"
              >
              <template slot="first">
                <b-form-select-option :value="null">
                  -- Por favor, selecione uma opção --
                </b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Setor</label>
              <GenericSelect
                name="Setor"
                labelKey="nome"
                v-model="filtersLista.id_setor"
                route="Setor"
                ref="filter_id_setor"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>

      </template>
    </GenericTable>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GenericSelect from '@/components/Form/GenericSelect';
import unidadeServices from '../../../services/unidade';
import loginServices from '../../../services/login';

export default {
  components: {
    GenericSelect,
  },

  props: {
    filtersLista: {
      type: Object,
    },
    tipoItemSelect: {
      type: Object,
    },
  },

  data() {
    return {
      materialRoute: 'item/inventario',
      filters: {
        descricao: '',
        id_tipo_material: null,
        id_unidade: null,
        code: '',
        id_setor: null,
        ids: '',
      },
      noAdd: false,
      hasBlocoOrCmeOrOpme: false,
      optionsUnidade: [
        { value: -1, text: 'Rede' },
      ],
    };
  },

  computed: {
    ...mapState(['previousRoute', 'filtersStored', 'currentUnidade']),
  },

  async created() {
    const user = loginServices.getUser();
    this.hasBlocoOrCmeOrOpme = user.hasBloco || user.hasCme || user.areas.includes('opme');
    if (!this.hasBlocoOrCmeOrOpme) {
      this.filters.id_unidade = this.currentUnidade.id_unidade;
      this.noAdd = true;
    }

    if (this.filtersStored.material) {
      if ([
        'materialRastrear', 'materialEditar', 'materialMedicoEditar',
      ].includes(this.previousRoute.name)) {
        this.filters = { ...this.filtersStored.material };
      } else {
        this.$store.commit('setFiltersStored', {});
      }
    }

    const unidades = await unidadeServices.getUnidades();
    this.optionsUnidade = this.optionsUnidade.concat(unidades.map((unid) => ({
      value: unid.id_unidade,
      text: unid.nome,
    })));
  },

};
</script>
